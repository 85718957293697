import { getSiPrefixedNumber } from '../../../utilities/SiPrefix';
import ArrowNarrowDownIcon from '../../../UI/atoms/SvgIcons/ArrowNarrowDownIcon';
import ArrowNarrowUpIcon from '../../../UI/atoms/SvgIcons/ArrowNarrowUpIcon';

export const columnComparator = (valueA, valueB) => {
  if (valueA.value === null && valueB.value === null) {
    return 0;
  }
  if (valueA.value === null) {
    return 1;
  }
  if (valueB.value === null) {
    return -1;
  }
  return valueA.value - valueB.value;
};

export const IconCellRenderer = (props) => {
  if (props.value.increase === 'increase') {
    return (
      <>
        <span className="trends-table-trends-icon arrow-up">
          <ArrowNarrowUpIcon height="18" width="18" />
        </span>
        <span className="ms-2">{Math.round(Math.abs(props.value.value))}%</span>
      </>
    );
  } else if (props.value.increase === 'decrease') {
    return (
      <>
        <span className="trends-table-trends-icon arrow-down">
          <ArrowNarrowDownIcon height="18" width="18" />
        </span>
        <span className="ms-1">{Math.round(Math.abs(props.value.value))}%</span>
      </>
    );
  } else if (props.value.increase === 'equal') {
    return (
      <>
        <span className="ms-2">{Math.round(Math.abs(props.value.value))}%</span>
      </>
    );
  } else {
    return (
      <>
        <span className="ms-2">-</span>
      </>
    );
  }
};

export const AccountCellRenderer = (props) => {
  return (
    <div className="mt-2 mb-2">
      <div className="trends-table-account-main-content">
        {props.value.Alias ? props.value.Alias : props.value.AccountId}
      </div>
      {props.value.Alias && (
        <div className="trends-table-account-sub-content"> ({props.value.AccountId})</div>
      )}
    </div>
  );
};
export const EmisssionsCellRenderer = (props) => {
  if (props.value) {
    return getSiPrefixedNumberGrams(props.value);
  } else {
    return '-';
  }
};
export const EnergyCellRenderer = (props) => {
  if (props.value) {
    return getSiPrefixedNumberWattHours(props.value);
  } else {
    return '-';
  }
};
//Adding required units for the emissions and energy values
export const getSiPrefixedNumberGrams = (value) => getSiPrefixedNumber(value) + 'g';
export const getSiPrefixedNumberWattHours = (value) => getSiPrefixedNumber(value) + 'Wh';
