import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import MainHeader from '../../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../../UI/atoms/Typography/DivTdsBody01';

const AzureIntegration = () => {
  return (
    <>
      <MainHeader>Data Collection</MainHeader>
      <DivTdsBody01>
        Our current method for collecting data from Azure is through an integration with a Storage
        Account Container where Cost Management Export data is available, but more integration
        options may become available in the future.
      </DivTdsBody01>
      <DivTdsBody01>
        <a
          href="https://learn.microsoft.com/en-us/azure/cost-management-billing/"
          rel="noreferrer"
          target="_blank"
          className="underline">
          Cost Management
        </a>{' '}
        is a service available from Azure that Cloud Emissions Tracker uses to extract Usage. data
        from Exports.
      </DivTdsBody01>
      <DivTdsBody01>
        The Cost Management Export(s) is created by Cloud Emissions Tracker&apos;s Integration.
        Package and is scheduled to export Usage data once per day.
      </DivTdsBody01>
      <DivTdsBody01>
        The integration setup comes with a small cost in your account since the Usage data is stored
        in a Storage Account Container (
        <a
          href="https://azure.microsoft.com/en-us/pricing/details/storage/blobs/"
          rel="noreferrer"
          target="_blank"
          className="underline">
          Azure Blob Storage Pricing
        </a>
        ).
      </DivTdsBody01>
      <DivTdsBody01>
        <i>
          <b>NOTE:</b> If you havn&apos;t already used Cost Management Export in the targeted
          subscription, you&apos;ll need to register that service as a Resource Provider.
        </i>
      </DivTdsBody01>
      <DivTdsBody01>
        Registering the Resource Provider can be achieved using the following Azure CLI command:
      </DivTdsBody01>
      <pre>
        az provider register
        <br />
        {'  '}--subscription {'${'}THE_SUBSCRIPTION_ID_WHERE_RESOURCES_WILL_BE_DEPLOYED{'}'}
        <br />
        {'  '}--namespace Microsoft.CostManagementExports
        <br />
        {'  '}--wait
      </pre>
      <DivTdsBody01>
        Once you are certain that the proper Resource Provider is registered, then you can start
        deployment of our integration package. A link to the downloadable package can be found
        further down this page.
      </DivTdsBody01>
      <DivTdsBody01>
        We recommend that you deploy the integration once on the topmost Management Group available.
        By doing so, all underlying subscriptions will be included and integrated automatically.
      </DivTdsBody01>
      <DivTdsBody01>
        Deploying our integration package can be achieved using the following Azure CLI command:
      </DivTdsBody01>
      <pre>
        az deployment mg create
        <br />
        {'  '}--template-file ./Integration.ManagementGroup.bicep
        <br />
        {'  '}--location {'${'}WHAT_LOCATION_RESOURCES_WILL_BE_CREATED_IN{'}'}
        <br />
        {'  '}--management-group-id {'${'}THE_TARGET_MANAGEMENTGROUP_ID_FOR_INTEGRATION{'}'}
        <br />
        {'  '}--parameters subscriptionId={'${'}
        THE_SUBSCRIPTION_ID_WHERE_RESOURCES_WILL_BE_DEPLOYED{'}'}
        <br />
        {'  '}--parameters resourceBaseName={'${'}WHAT_RESOURCE_NAMING_WILL_BE_BASED_ON{'}'}
        <br />
        <span>
          {/* style={{ whiteSpace: 'no-wrap', display: 'block' }}> */}
          {'  '}--parameters idThatShouldHaveAccessToKeyVault=
          {/* </span> */}
          {/* <span style={{ whiteSpace: 'no-wrap', display: 'block' }} className="ms-5"> */}
          {'${'} PRINCIPAL_ID_THAT_WILL_GET_ACCESS_TO_VAULT_SECRETS{'}'}
        </span>
      </pre>
      <MainHeader>Infrastructure as Code</MainHeader>
      <DivTdsBody01>
        We seldom do manual operations, and deploying infrastructure is no exception. Therefore, we
        offer our integration in the form of Infrastructure as Code for you to deploy on your side
        when integrating with us.
      </DivTdsBody01>
      <DivTdsBody01>
        For Azure environments, all required resources are defined and bundled into this package of
        Bicep files:
      </DivTdsBody01>
      <a href="downloads/CetIntegrationPackageV0.1.80.zip" download>
        <TdsButton className="tds-u-mb2" size="md" text="Download Bicep files"></TdsButton>
      </a>
    </>
  );
};

export default AzureIntegration;
