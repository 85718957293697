import React from 'react';
import DivTdsBody01 from '../../UI/atoms/Typography/DivTdsBody01';
import RecommendationsThreeRs from './Components/RecommendationsThreeRs';
import RecommendationsRelocation from './Components/RecommendationsRelocation';
import RecommendationsElectricityMap from './Components/RecommendationsElectricityMap';
import RecommendationsRightsizing from './Components/RecommendationsRightsizing';
import RecommendationsRearchitecting from './Components/RecommendationsRearchitecting';
import RecommendationsIntro from './Components/RecommendationsIntro';

function Recommendations() {
  return (
    <div className={'tds-container'}>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <h2>Recommendations</h2>
          <DivTdsBody01>
            This page covers the principles that we believe are important to follow when trying to
            minimise the carbon footprint of your IT systems. These principles promote efficient
            energy use, reduce waste and encourage careful selection of regions where your code
            runs.
          </DivTdsBody01>
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col-xs-12 tds-col-md-6'}>
          <RecommendationsIntro />
        </div>
        <div className={'tds-col-xs-12 tds-col-md-6'}>
          <RecommendationsThreeRs />
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <h2>Relocation</h2>
        </div>
      </div>

      <div className={'tds-row'}>
        <div className={'tds-col-lg-8 tds-col-xs-12'}>
          <RecommendationsRelocation />
        </div>
        <div className={'tds-col-lg-4 tds-col-xs-12'}>
          <RecommendationsElectricityMap />
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <RecommendationsRightsizing />
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <RecommendationsRearchitecting />
        </div>
      </div>
    </div>
  );
}

export default Recommendations;
