import React from 'react';
import { Link } from 'react-router-dom';
import config, { app_base_path_with_leading_slash } from '../../../../config';
import MainHeader from '../../../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../../../UI/atoms/Typography/DivTdsBody01';

const DevelopersAuthentication = () => {
  return (
    <>
      <MainHeader>Create an API key</MainHeader>
      <DivTdsBody01>
        Navigate to your{' '}
        <Link to={app_base_path_with_leading_slash + '/settings/apikey'}>settings page</Link>. to
        create an API key. This function is not available in mobile view.
      </DivTdsBody01>
      <MainHeader>Using your API key</MainHeader>
      <DivTdsBody01>
        To use your API key, prefix it with <code>Basic</code> add it to the request as a header
        named <code>Authorization</code>.
      </DivTdsBody01>
      <MainHeader>Example</MainHeader>
      <DivTdsBody01>
        The following is an example if request is performed as a cURL command:
      </DivTdsBody01>
      <DivTdsBody01>
        <code>
          <span className="code-shell-cmd">curl</span> &apos;{config.API_GATEWAY_AUTH_URL}
          /v1/auth/user&apos; <span className="code-curl-options">-X</span> GET{' '}
          <span className="code-curl-options">-H</span> &apos;Authorization: Basic
          &lt;API_KEY&gt;&apos;
        </code>
      </DivTdsBody01>
    </>
  );
};

export default DevelopersAuthentication;
