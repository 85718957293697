import React from 'react';
import { Link } from 'react-router-dom';
import { app_base_path_with_leading_slash } from '../../../../config';
import DivTdsBody01 from '../../../../UI/atoms/Typography/DivTdsBody01';

const DevelopersIntroduction = () => {
  return (
    <>
      <h2>Introduction</h2>
      <DivTdsBody01>
        To get started using the Cloud Emissions Tracker APIs we recommend that you read this
        documentation before starting.
      </DivTdsBody01>
      <DivTdsBody01>
        Access to the APIs is granted using API keys. Find out how to do it{' '}
        <Link to={app_base_path_with_leading_slash + '/developers/authentication'}>here</Link>.
      </DivTdsBody01>
      <h2>Security</h2>
      <DivTdsBody01>
        The API keys generated by Cloud Emissions Tracker must be treated as a secret and never be
        revealed to anyone. If you believe a key has been compromised you should immediately revoke
        it and generate a new one.
      </DivTdsBody01>
      <DivTdsBody01>
        Your API keys will be assigned the same permissions that you have.
      </DivTdsBody01>
      <h2>Throttling</h2>
      <DivTdsBody01>
        API calls can be throttled to protect against DDoS-attacks and human mistakes.
      </DivTdsBody01>
      <ul>
        <li>
          <DivTdsBody01>10 requests per second</DivTdsBody01>
        </li>
        <li>
          <DivTdsBody01>12 000 requests per day</DivTdsBody01>
        </li>
      </ul>
    </>
  );
};

export default DevelopersIntroduction;
