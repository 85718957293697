import React, { useContext } from 'react';
import AuthContext from './contexts/common/AuthContext';
import AlertContext from './contexts/common/AlertContext';
import config, { app_base_path_with_leading_slash, app_path_prefix } from './config';
import { TdsToast } from '@scania/tegel-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import DemoLoginPanel from './components/LoginPanel/DemoLoginPanel';
import LoginPanel from './components/LoginPanel/LoginPanel';
import PrivateRoute from './components/common/Auth/PrivateRoute';
import Home from './pages/Home/Home';
import { Trends } from './pages/Trends/Trends';
import Emissions from './scenes/Emissions/Emissions';
import About from './pages/About/About';
import Glossary from './pages/About/Glossary';
import Methodology from './pages/Methodology/Methodology';
import Developers from './pages/Developers/Developers';
import Integration from './pages/Integration/Integration';
import Recommendations from './pages/Recommendations/Recommendations';
import Settings from './scenes/Settings/Settings';
import ErrorNotFound from './pages/Error/ErrorNotFound';
import DevelopersIntroduction from './pages/Developers/Components/Introduction/DevelopersIntroduction';
import DevelopersAuthentication from './pages/Developers/Components/Auth/DevelopersAuthentication';
import DevelopersDataApi from './pages/Developers/Components/DataAPI/DevelopersDataApi';
import DevelopersAccessApi from './pages/Developers/Components/AccessAPI/DevelopersAccessApi';

function SceneRouter() {
  const auth = useContext(AuthContext);
  const alertsContext = useContext(AlertContext);

  const getPathnameWithoutPrefix = (prefix) => {
    let pathname = window.location.pathname;
    return pathname.startsWith(`/${prefix}/`)
      ? pathname.replace('/', '').replace(`${prefix}/`, '')
      : pathname.replace('/', '');
  };

  const isNavigatedToPage = (page) => {
    return getPathnameWithoutPrefix(app_path_prefix).toLowerCase() === page;
  };

  return (
    <>
      {auth.isLoggedIn() || isNavigatedToPage('login') ? (
        <React.Fragment>
          <div>
            <Routes>
              <Route
                path={app_base_path_with_leading_slash + '/login'}
                element={config.ENVIRONMENT === 'DEMO' ? <DemoLoginPanel /> : <LoginPanel />}
              />
              <Route element={<PrivateRoute />}>
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/'}
                  element={<Navigate to={app_base_path_with_leading_slash + '/emissions'} />}
                />
                <Route
                  exact
                  path="/"
                  element={<Navigate to={app_base_path_with_leading_slash + '/emissions'} />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/trends'}
                  element={<Navigate to={app_base_path_with_leading_slash + '/compare'} />}
                />
                <Route exact path={app_base_path_with_leading_slash + '/home'} element={<Home />} />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/compare'}
                  element={<Trends />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/emissions/*'}
                  element={<Emissions />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/about'}
                  element={<About />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/glossary'}
                  element={<Glossary />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/methodology/*'}
                  element={<Methodology />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/developers'}
                  element={<Developers />}>
                  <Route index element={<DevelopersIntroduction />} />
                  <Route path={'authentication'} element={<DevelopersAuthentication />} />
                  <Route path={'data'} element={<DevelopersDataApi />} />
                  <Route path={'access'} element={<DevelopersAccessApi />} />
                </Route>
                <Route
                  path={app_base_path_with_leading_slash + '/integration'}
                  element={<Navigate to={app_base_path_with_leading_slash + '/integration/aws'} />}
                />
                <Route
                  exact
                  path={app_base_path_with_leading_slash + '/integration/*'}
                  element={<Integration />}
                />
                <Route
                  path={app_base_path_with_leading_slash + '/recommendations'}
                  element={<Recommendations />}
                />
                <Route
                  path={app_base_path_with_leading_slash + '/developers'}
                  element={<Developers />}
                />
                <Route
                  exact
                  index
                  path={app_base_path_with_leading_slash + '/settings/*'}
                  element={<Settings />}
                />
              </Route>
              <Route path={app_base_path_with_leading_slash + '/404'} element={<ErrorNotFound />} />
              <Route
                path="*"
                element={<Navigate to={app_base_path_with_leading_slash + '/404'} />}
              />
            </Routes>
          </div>
          {Object.entries(alertsContext.alerts).map(([, value]) => {
            return (
              <TdsToast
                key={value.key}
                header={value.error ? 'Error' : 'Success'}
                subheader={value.message}
                onClose={value.onC}
                variant={value.error ? 'error' : 'information'}
              />
            );
          })}
        </React.Fragment>
      ) : null}
    </>
  );
}

export default SceneRouter;
