import React from 'react';
import config from '../../../../config';
import { TdsBlock } from '@scania/tegel-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/src/styles/hljs';
import DivTdsBody01 from '../../../../UI/atoms/Typography/DivTdsBody01';
import ApiDescriptionContainer from '../ApiDescriptionContainer';
import MainHeader from '../../../../components/atoms/Typography/MainHeader';
import SectionLayout from '../../../../components/atoms/Section/SectionLayout';

const exampleResponse = {
  Email: 'user.one@yourcompany.eu',
  AssociatedCustomerIds: ['YourReferenceId']
};

function DevelopersAccessApiGetUser() {
  return (
    <>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <ApiDescriptionContainer
          method={'get'}
          path={'/v1/auth/user'}
          description={
            'This endpoint returns information of the user requesting, ' +
            'especially information regarding customer and account/subscription ' +
            'associations together with different access permissions.'
          }>
          <SectionLayout header={'Required Permissions'}>
            <DivTdsBody01>View permission on the resources that you are accessing.</DivTdsBody01>
          </SectionLayout>
        </ApiDescriptionContainer>
      </div>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <TdsBlock>
          <MainHeader>Example</MainHeader>
          <h3>Request</h3>
          <SyntaxHighlighter language="sh" style={docco} wrapLongLines>
            {"curl '" +
              config.API_GATEWAY_AUTH_URL +
              '/v1/auth/user\' -X GET -H "Authorization: Basic $API_KEY"'}
          </SyntaxHighlighter>

          <h3>Response</h3>
          <h4>Status Codes</h4>
          <code>
            200 <span className="code-general-divider">|</span> 401
          </code>
          <h4>
            Payload Data <span style={{ fontSize: '0.75rem' }}>(200)</span>
          </h4>
          <SyntaxHighlighter language="json" style={docco} wrapLongLines>
            {JSON.stringify(exampleResponse, null, 2)}
          </SyntaxHighlighter>
        </TdsBlock>
      </div>
    </>
  );
}

export default DevelopersAccessApiGetUser;
