import React from 'react';
import MainHeader from '../../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../../UI/atoms/Typography/DivTdsBody01';

function MethodologyEC2() {
  return (
    <React.Fragment>
      <MainHeader>Notes on Amazon EC2</MainHeader>
      <DivTdsBody01>
        In the recent update to our calculation model, we pay more attention to service usage than
        before. Due to this you might notice some peaks and troughs in your emissions that
        weren&apos;t there previously. There could be multiple reasons for this, but one of the main
        causes could be the usage of{' '}
        <a
          href={
            'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/burstable-performance-instances.html'
          }>
          burstable instances in AWS
        </a>
        . Not all server types are burstable. At the time of writing, the instance types with
        support are t4g, t3a, t3 and t2.
      </DivTdsBody01>
      <DivTdsBody01>
        The baseline performance of a burstable EC2 server is given as a percentage of the maximum
        CPU usage, and it is on the percentage that the energy and emissions is calculated. As the
        server uses credits (higher CPU than baseline) then credits <i>may</i> be spent. In Gaia,
        this could result in higher energy usage and emissions.
      </DivTdsBody01>
      <DivTdsBody01>
        Another reason for fluctuations in emissions from EC2 could be due to storage, memory and
        networking, which previously wasn&apos;t considered. If you previously noticed a drop to
        zero emissions when your server is stopped, but now that value is non-zero, then this is
        likely due to your usage of block store in the EC2 service (EBS).
      </DivTdsBody01>
    </React.Fragment>
  );
}

export default MethodologyEC2;
