import React from 'react';
import './Recommendations.css';
import { TdsBlock } from '@scania/tegel-react';
import MainHeader from '../../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../../UI/atoms/Typography/DivTdsBody01';

function RecommendationsIntro() {
  return (
    <TdsBlock>
      <MainHeader> Efficient IT Systems</MainHeader>
      <DivTdsBody01>
        An IT system is environmentally efficient if it consumes just enough energy to run the
        system, but maximises its use of renewable and fossil-free energy. <br />
        <br />
        An environmentally efficient IT system can be achieved by following the &quot;Three
        Rs&quot;.
      </DivTdsBody01>
    </TdsBlock>
  );
}

export default RecommendationsIntro;
