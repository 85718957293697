import React from 'react';
import LineChart from '../../components/Charts/LineChart';
import EmissionsContent from '../../components/EmissionsContent/EmissionsContent';
import EmissionsFilter from '../../components/Filters/EmissionsFilter';

function Emissions() {
  return (
    <EmissionsContent>
      <EmissionsFilter />
      <LineChart />
    </EmissionsContent>
  );
}

export default Emissions;
