import React from 'react';
import DevelopersDataApiDescription from './DevelopersDataApiDescription';
import DevelopersDataApiExample from './DevelopersDataApiExample';
import DivTdsBody01 from '../../../../UI/atoms/Typography/DivTdsBody01';
import config from '../../../../config';
import MainHeader from '../../../../components/atoms/Typography/MainHeader';

const DevelopersDataApi = () => {
  return (
    <>
      <MainHeader>Data API</MainHeader>
      <DivTdsBody01>
        <b>Base URL</b>: <code>{config.API_GATEWAY_EMISSIONS_URL}</code>
      </DivTdsBody01>
      <div className={'tds-container-no-padding'}>
        <div className={'tds-row'}>
          <div className={'tds-col-xs-12 tds-col-lg-6'}>
            <DevelopersDataApiDescription />
          </div>
          <div className={'tds-col-xs-12 tds-col-lg-6'}>
            <DevelopersDataApiExample />
          </div>
        </div>
      </div>
    </>
  );
};

export default DevelopersDataApi;
