import React from 'react';
import './Recommendations.css';
import MainHeader from '../../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../../UI/atoms/Typography/DivTdsBody01';

function RecommendationsRightsizing() {
  return (
    <React.Fragment>
      <MainHeader>Rightsizing</MainHeader>
      <DivTdsBody01>
        Rightsizing is the process of changing the capacity of a resource to meet system
        requirements more exactly. Use no more or less that you need. It will help make your system
        environmentally efficient by reducing the amount of energy it consumes. A nice side-effect
        of rightsizing is that operational cost will reduce because less powerful computers
        typically cost less.
      </DivTdsBody01>
      <h5>
        &ldquo;&nbsp;
        <span className="recommendations-rightsizing-text-quote">
          The <b>greenest energy</b> is the energy <b>you don&apos;t use</b>
          !&nbsp;&rdquo;
        </span>
      </h5>
    </React.Fragment>
  );
}

export default RecommendationsRightsizing;
